<template>
    <div class="flex flex-col gap-5">
        <template v-if="product.hasVariations">
            <ProductVariationSelector
                v-for="property in variationProperties"
                :key="property.id!"
                v-model="selectedAttributes[property.id!]"
                :available-variations="getAvailableVariations(property)"
                :property="property"
                :loading="variationsLoading"
                selector-class="flex flex-wrap"
                @change="handlePropertyChange"
            />

            <ProductVariationSelector
                v-if="showFinalVariationSelector"
                v-model:variation="selectedVariation"
                :indistinguishable-variations="finalVariations"
                :title="$t('product.variation_selector_title')"
                :loading="variationsLoading"
            />

            <hr>
        </template>

        <div class="flex flex-wrap items-end justify-between gap-4">
            <div class="sim-product-detail-controls__stock">
                <!--  Known availability  -->
                <span v-if="product.hasAvailability(selectedVariation)" class="flex gap-1" :class="!product.isAvailable(selectedVariation) ? 'text-danger-500' : 'text-success-500'">
                    <span class="uppercase">
                        {{ product.getAvailabilityLabel(selectedVariation) }}
                    </span>

                    <span v-if="product.isAvailable(selectedVariation)" :class="{ 'text-success-500': product.canBePurchased(selectedVariation) }">
                        {{ product.getFormattedStockCount(selectedVariation) }}
                    </span>
                </span>

                <!--  Unknown availability  -->
                <span v-else class="text-warning-500 flex gap-1">
                    <span class="uppercase">
                        {{ $t('product.unknown_availability') }}
                    </span>

                    <span v-if="product.hasStock(selectedVariation)" :class="{ 'text-success-500': product.canBePurchased(selectedVariation) }">
                        {{ product.getFormattedStockCount(selectedVariation) }}
                    </span>
                </span>
            </div>

            <ProductPrice :product="product"
                          :selected-variation="selectedVariation"
                          type="detail"
            />
        </div>


        <div class="flex flex-wrap gap-x-4 gap-y-6">
            <UiCounter v-model="amount"
                       counter-class="bg-primary-200"
                       :max-value="product.getMaxPurchasableAmount(selectedVariation)"
                       :disabled="product.isAddToCartButtonDisabled(selectedVariation)"
            />

            <ProductAddToCartButton
                :disabled="product.isAddToCartButtonDisabled(selectedVariation)"
                :product="product"
                :amount="amount"
                :product-variation-id="selectedVariation?.id"
                :label="$t('product.add_to_cart')"
            />
        </div>

        <div class="mt-2 flex flex-col gap-4">
            <span class="sim-text-small flex gap-2">
                <IconTruck size="sm" />

                <i18n-t keypath="product.stock_info" tag="span" scope="global">

                    <template #moreInfo>
                        <strong class="sim-font-bold">
                            {{ $t('product.stock_info_more') }}
                        </strong>
                    </template>
                </i18n-t>
            </span>

            <span v-if="product.getSku(selectedVariation)" class="sim-text-small flex gap-2">
                <IconBox size="sm" />

                {{ $t('product.sku') }}:
                {{ product.getSku(selectedVariation) }}
            </span>
        </div>
    </div>
</template>

<script lang="ts" setup>
const {
    product,
    variationsLoading,
} = defineProps<{
    product: InstanceType<typeof ProductModel>
    variationsLoading: boolean
}>()

const amount = ref<number>(1)

const {
    variationProperties,
    selectedVariation,
    finalVariations,
    selectedAttributes,
    handlePropertyChange,
    getAvailableVariations,
    showFinalVariationSelector,
} = useProductVariationSelector()

</script>

<style lang="scss">
.sim-product-detail-controls__variants {
    width: 100%;

    padding-top: 0.85rem;

    color: $sim-c-primary-900;
    font-weight: 500;

    border-top: 1px solid $sim-c-basic-400;
}

.sim-product-detail-controls__stock {
    @include sim-text-small;
    @include sim-letters-widespread;
    @include sim-font-bold;
}

</style>
