<script lang="tsx">
import type { SlotsType, VNode } from 'vue'
import type { BaseUiRatingProps } from './../BaseUiRating.vue'
import type { BaseUiFormGroupProps, BaseUiFormGroupSlots } from './BaseUiFormGroup.vue'
import { BaseUiFormGroup, BaseUiRating } from '#components'
import type { InternalFormElementValue } from '@core-types/components/CoreUIForm'
import type { SizeProp } from '@core-types/components'

export type BaseUiFormRatingProps<Sizes extends string> = BaseUiFormGroupProps & Omit<
    BaseUiRatingProps<Sizes>, 'editable'
>

type BaseUiFormRatingSlots = Omit<BaseUiFormGroupSlots, 'default'> & {
    default: {}
}

type ComponentOptions = {}

export function defineComponentBaseUiFormRating<
    Sizes extends string = SizeProp
>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiFormRatingProps<Sizes>, BaseUiFormRatingSlots>) {
    return defineComponent(
        (props: BaseUiFormRatingProps<Sizes>, ctx) => {

            return () => (
                <BaseUiFormGroup
                    form={props.form}
                    help={props.help}
                    descriptionAbove={props.descriptionAbove}
                    descriptionBelow={props.descriptionBelow}
                    hideRequired={props.hideRequired}
                    label={props.label}
                    noLabelElement={props.noLabelElement}
                    errorId={props.errorId}
                    descriptionId={props.descriptionId}
                    disabled={props.disabled}
                    loading={props.loading}
                    required={props.required}
                    ariaLabel={props.ariaLabel}
                    ariaInvalid={props.ariaInvalid}
                >
                    {(slotData: BaseUiFormGroupSlots['default']) => [
                        slotData.renderLabel(
                            (
                                renderSlot(ctx.slots.default, options?.slots?.default, {}, (
                                    <>
                                        {props.label}
                                    </>
                                ))
                            ) as VNode,
                            {
                                hasDefaultSlotContent: ctx.slots.default !== undefined || !!options?.slots?.default,
                            }
                        ),

                        slotData.renderAboveDescription(),

                        (
                            <BaseUiRating
                                modelValue={props.modelValue}
                                onUpdate:modelValue={(val: number) => ctx.emit('update:modelValue', val)}
                                modelModifiers={props.modelModifiers}
                                form={props.form}
                                onUpdate:form={(val: InternalFormElementValue<number | null>) => ctx.emit('update:form', val)}
                                formModifiers={props.formModifiers}

                                id={slotData.inputId}
                                disabled={props.disabled}
                                loading={props.loading}
                                required={slotData.isInputRequired}
                                descriptionId={slotData.inputDescriptionIds}
                                ariaLabel={props.ariaLabel}
                                ariaInvalid={slotData.isInputAriaInvalid}

                                value={props.value}
                                maxRating={props.maxRating}
                                // @ts-ignore
                                size={props.size}
                                editable
                            />
                        ),

                        slotData.renderBelowDescriptionAndError(),
                    ]}
                </BaseUiFormGroup>
            )
        },
        {
            props: {
                modelValue: {
                    type: Number as PropType<BaseUiFormRatingProps<Sizes>['modelValue']>,
                    default: options?.props?.modelValue?.default,
                    required: options?.props?.modelValue?.required ?? false,
                },
                form: {
                    type: Object as PropType<BaseUiFormRatingProps<Sizes>['form']>,
                    default: options?.props?.form?.default,
                    required: options?.props?.form?.required ?? false,
                },
                disabled: {
                    type: Boolean as PropType<BaseUiFormRatingProps<Sizes>['disabled']>,
                    default: options?.props?.disabled?.default,
                    required: options?.props?.disabled?.required ?? false,
                },
                loading: {
                    type: Boolean as PropType<BaseUiFormRatingProps<Sizes>['loading']>,
                    default: options?.props?.loading?.default,
                    required: options?.props?.loading?.required ?? false,
                },
                required: {
                    type: Boolean as PropType<BaseUiFormRatingProps<Sizes>['required']>,
                    default: options?.props?.required?.default,
                    required: options?.props?.required?.required ?? false,
                },
                ariaLabel: {
                    type: String as PropType<BaseUiFormRatingProps<Sizes>['ariaLabel']>,
                    default: options?.props?.ariaLabel?.default,
                    required: options?.props?.ariaLabel?.required ?? false,
                },
                ariaInvalid: {
                    type: Boolean as PropType<BaseUiFormRatingProps<Sizes>['ariaInvalid']>,
                    default: options?.props?.ariaInvalid?.default,
                    required: options?.props?.ariaInvalid?.required ?? false,
                },

                help: {
                    type: String as PropType<BaseUiFormRatingProps<Sizes>['help']>,
                    default: options?.props?.help?.default,
                    required: options?.props?.help?.required ?? false,
                },
                descriptionAbove: {
                    type: String as PropType<BaseUiFormRatingProps<Sizes>['descriptionAbove']>,
                    default: options?.props?.descriptionAbove?.default,
                    required: options?.props?.descriptionAbove?.required ?? false,
                },
                descriptionBelow: {
                    type: String as PropType<BaseUiFormRatingProps<Sizes>['descriptionBelow']>,
                    default: options?.props?.descriptionBelow?.default,
                    required: options?.props?.descriptionBelow?.required ?? false,
                },
                hideRequired: {
                    type: Boolean as PropType<BaseUiFormRatingProps<Sizes>['hideRequired']>,
                    default: options?.props?.hideRequired?.default,
                    required: options?.props?.hideRequired?.required ?? false,
                },
                label: {
                    type: String as PropType<BaseUiFormRatingProps<Sizes>['label']>,
                    default: options?.props?.label?.default,
                    required: options?.props?.label?.required ?? false,
                },
                errorId: {
                    type: String as PropType<BaseUiFormRatingProps<Sizes>['errorId']>,
                    default: options?.props?.errorId?.default,
                    required: options?.props?.errorId?.required ?? false,
                },
                descriptionId: {
                    type: [String, Array] as PropType<BaseUiFormRatingProps<Sizes>['descriptionId']>,
                    default: options?.props?.descriptionId?.default,
                    required: options?.props?.descriptionId?.required ?? false,
                },

                value: {
                    type: Number as PropType<BaseUiFormRatingProps<Sizes>['value']>,
                    default: options?.props?.value?.default,
                    required: options?.props?.value?.required ?? false,
                },
                maxRating: {
                    type: Number as PropType<BaseUiFormRatingProps<Sizes>['maxRating']>,
                    default: options?.props?.maxRating?.default,
                    required: options?.props?.maxRating?.required ?? false,
                },
                size: {
                    // @ts-ignore
                    type: String as PropType<BaseUiFormRatingProps<Sizes>['size']>,
                    default: options?.props?.size?.default,
                    required: options?.props?.size?.required ?? false,
                },
            },
            slots: Object as SlotsType<BaseUiFormRatingSlots>,
            emits: {
                'update:modelValue': (value: number) => true,
                'update:form': (value: InternalFormElementValue<number | null>) => true,
            },
        }
    )
}

export default defineComponentBaseUiFormRating()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiFormRating.scss" as *;

</style>
