<template>
    <UiButton
        color="primary"
        size="giant"
        :outlined="outlined"
        class="flex-1 whitespace-nowrap"
        :disabled="disabled"
        @click="handleClick"
    >
        {{ label }}

        <template #trailing>
            <IconShoppingBag class="hidden md:block" />
        </template>
    </UiButton>
</template>

<script lang="ts" setup>
const {
    product,
    amount,
    productVariationId,
    label,
    outlined,
    disabled,
} = defineProps<{
    product: InstanceType<typeof ProductModel> | null
    amount: number
    productVariationId?: number | null
    label: string
    outlined?: boolean
    disabled?: boolean
}>()

const { addToCart } = useCart()

async function handleClick() {
    if (!product?.id) return

    await addToCart({
        product_id: product.id,
        amount: amount,
        variation_id: productVariationId,
    })
}

</script>

<style lang="scss">

</style>
