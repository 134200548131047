<script lang="tsx">
import { defineComponentBaseUiRating } from '@core/app/components/base/ui/BaseUiRating.vue'
import { IconStar } from '#components'
import type { CoreIconSizes } from '@core-types/components/CoreIcon'

type Sizes = CoreIconSizes

export default defineComponentBaseUiRating<Sizes>({
    props: {
        size: {
            default: 'md',
        },
    },
    slots: {
        item: ({ size }) => <IconStar size={size} />,
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiRating" as *;

@include item {
    color: $sim-c-simplo;

    transition: color $sim-trans-time-short $sim-timing, fill $sim-trans-time-short $sim-timing;
}

@include item--active {
    fill: $sim-c-simplo;
}

@include item--disabled {
    color: $sim-c-basic-400;
}

@include item--editable--before-hover {
    color: $sim-c-primary-600;
    fill: $sim-c-primary-600;
}

@include item--editable--after-hover {
    fill: transparent;
}

</style>
