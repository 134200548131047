<template>
    <CoreUiForm
        ref="form"
        v-slot="{ formData, globalFormError, isFormSubmitting }"
        :schema="schema"
        :on-submit="onSubmit"
        notify-on-error="all"
    >
        <CoreUiFormRow>
            <BaseUiFormRating
                v-model:form="formData.rating"
                :label="$t('product.rating.total')"
                size="lg"
            />
        </CoreUiFormRow>

        <CoreUiFormRow v-if="!loggedIn">
            <!--  NAME INPUT  -->
            <BaseUiFormInput
                v-model:form="formData.name"
                color="primary"
                :label="$t('labels.name')"
                :placeholder="$t('labels.your_name')"
                autocomplete="name"
            />
        </CoreUiFormRow>

        <CoreUiFormRow>
            <!--  REVIEW TEXTAREA  -->
            <BaseUiFormInput
                v-model:form="formData.comment"
                type="textarea"
                color="primary"
                :rows="5"
                :label="$t('labels.review')"
                :placeholder="$t('labels.your_review')"
            />
        </CoreUiFormRow>

        <CoreUiFormRow indented>
            <UiButton class="grow"
                      color="primary"
                      type="submit"
                      :aria-describedby="globalFormError.id"
                      :loading="isFormSubmitting"
                      size="large"
            >
                {{ $t('labels.submit') }}
            </UiButton>

            <BaseUiFormError v-if="globalFormError.message" :error-id="globalFormError.id">
                {{ globalFormError.message }}
            </BaseUiFormError>
        </CoreUiFormRow>
    </CoreUiForm>
</template>

<script lang="ts" setup>
import type { ComponentInstance } from '@core-types/utility'
import { CoreUiForm } from '#components'
import { z } from 'zod'

const {
    onSubmit,
} = defineProps<{
    onSubmit: (data: z.infer<typeof schema.value>) => void | Promise<void>
}>()

const { loggedIn } = useAuth()

const form = ref<ComponentInstance<typeof CoreUiForm> | null>(null)
const schema = computed(() => z.object({
    rating: z.number(),
    ...zRequiredIf({
        name: z.string(),
    }, !loggedIn.value),
    comment: z.string(),
}))

export type schema = z.infer<typeof schema.value>

defineExpose({
    formComponent: form,
})

</script>

<style lang="scss" scoped>

</style>
