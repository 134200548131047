<script lang="tsx">
import type { SlotsType } from 'vue'
import type { BaseFormElementProps, SizeProp } from '@core-types/components'
import { CoreProvideRadioGroup, CoreInternalCheckbox } from '#components'
import type { InternalFormElementValue } from '@core-types/components/CoreUIForm'

export type BaseUiRatingProps<Sizes extends string> = {
    value?: number | null
    maxRating?: number
    editable?: boolean
    size?: Sizes
} & BaseFormElementProps<number | null>

type BaseUiRatingSlots<Sizes extends string> = {
    default: {}
    item: {
        size: Sizes | undefined
        isActive: boolean
    }
}

type ComponentOptions = {}

export function defineComponentBaseUiRating<
    Sizes extends string = SizeProp,
>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiRatingProps<Sizes>, BaseUiRatingSlots<Sizes>>) {
    return defineComponent(
        (props: BaseUiRatingProps<Sizes>, ctx) => {
            const { t } = useI18n()

            const modelValue = computed<number | null | undefined>({
                get() {
                    return props.modelValue ?? null
                },
                set(val) {
                    if (val === undefined || val === null) return
                    ctx.emit('update:modelValue', val)
                },
            })

            const formModelValue = computed<InternalFormElementValue<number | null> | undefined>({
                get() {
                    return props.form
                },
                set(val) {
                    if (val === undefined) return
                    ctx.emit('update:form', val)
                },
            })

            const internalValue = computed<number | null>(() => {
                return modelValue.value ?? formModelValue.value?.__v ?? props.value ?? null
            })

            const roundedValue = computed(() => Math.round(internalValue.value ?? 0))

            const isDisabled = computed<boolean>(() => !!(props.loading || props.disabled))

            return () => (
                <div class={['sim-rating', {
                    'sim-rating--editable': props.editable,
                    'sim-rating--disabled': isDisabled.value,
                }]} aria-label={t('_core_simploshop.labels.total_rating', [roundedValue.value, props.maxRating])}>
                    {
                        props.editable
                            ? (
                                <CoreProvideRadioGroup
                                    v-model={modelValue.value}
                                    v-model:form={formModelValue.value}
                                >
                                    {
                                        Array.from({ length: props.maxRating ?? 0 }).map((_, i) => {
                                            const rating = i + 1

                                            return (
                                                <CoreInternalCheckbox
                                                    class={['sim-rating__item-wrapper', {
                                                        'sim-rating__item-wrapper--active': rating <= roundedValue.value,
                                                    }]}
                                                    value={rating}
                                                    id={props.id}
                                                    required={props.required}
                                                    disabled={props.disabled}
                                                    descriptionId={props.descriptionId}
                                                    ariaInvalid={props.ariaInvalid}
                                                    ariaLabel={props.ariaLabel}
                                                    type="radio"
                                                >
                                                    {{
                                                        radio: () => <div class="sim-rating__item">
                                                            {
                                                                renderSlot(ctx.slots.item, options?.slots?.item, {
                                                                    size: props.size,
                                                                    isActive: rating <= roundedValue.value,
                                                                })
                                                            }
                                                        </div>,
                                                    }}
                                                </CoreInternalCheckbox>
                                            )
                                        })
                                    }
                                </CoreProvideRadioGroup>

                            )
                            : Array.from({ length: props.maxRating ?? 0 }).map((_, i) => {
                                const rating = i + 1

                                // NON-EDITABLE RATING
                                return <span
                                    class={['sim-rating__item', {
                                        'sim-rating__item--active': rating <= roundedValue.value,
                                    }]}
                                    aria-hidden="true"
                                >
                                    {
                                        renderSlot(ctx.slots.item, options?.slots?.item, {
                                            size: props.size,
                                            isActive: rating <= roundedValue.value,
                                        })
                                    }
                                </span>
                            })
                    }
                </div>
            )
        },
        {
            props: {
                modelValue: {
                    type: Number as PropType<BaseUiRatingProps<Sizes>['modelValue']>,
                    default: options?.props?.modelValue?.default,
                    required: options?.props?.modelValue?.required ?? false,
                },
                modelModifiers: {
                    type: Object as PropType<BaseUiRatingProps<Sizes>['modelModifiers']>,
                    default: options?.props?.modelModifiers?.default,
                    required: options?.props?.modelModifiers?.required ?? false,
                },
                form: {
                    type: Object as PropType<BaseUiRatingProps<Sizes>['form']>,
                    default: options?.props?.form?.default,
                    required: options?.props?.form?.required ?? false,
                },
                formModifiers: {
                    type: Object as PropType<BaseUiRatingProps<Sizes>['formModifiers']>,
                    default: options?.props?.formModifiers?.default,
                    required: options?.props?.formModifiers?.required ?? false,
                },
                disabled: {
                    type: Boolean as PropType<BaseUiRatingProps<Sizes>['disabled']>,
                    default: options?.props?.disabled?.default,
                    required: options?.props?.disabled?.required ?? false,
                },
                loading: {
                    type: Boolean as PropType<BaseUiRatingProps<Sizes>['loading']>,
                    default: options?.props?.loading?.default,
                    required: options?.props?.loading?.required ?? false,
                },
                required: {
                    type: Boolean as PropType<BaseUiRatingProps<Sizes>['required']>,
                    default: options?.props?.required?.default,
                    required: options?.props?.required?.required ?? false,
                },
                id: {
                    type: String as PropType<BaseUiRatingProps<Sizes>['id']>,
                    default: options?.props?.id?.default,
                    required: options?.props?.id?.required ?? false,
                },
                descriptionId: {
                    type: [String, Array] as PropType<BaseUiRatingProps<Sizes>['descriptionId']>,
                    default: options?.props?.descriptionId?.default,
                    required: options?.props?.descriptionId?.required ?? false,
                },
                ariaLabel: {
                    type: String as PropType<BaseUiRatingProps<Sizes>['ariaLabel']>,
                    default: options?.props?.ariaLabel?.default,
                    required: options?.props?.ariaLabel?.required ?? false,
                },
                ariaInvalid: {
                    type: Boolean as PropType<BaseUiRatingProps<Sizes>['ariaInvalid']>,
                    default: options?.props?.ariaInvalid?.default,
                    required: options?.props?.ariaInvalid?.required ?? false,
                },

                value: {
                    type: Number as PropType<BaseUiRatingProps<Sizes>['value']>,
                    default: options?.props?.value?.default,
                    required: options?.props?.value?.required ?? false,
                },
                maxRating: {
                    type: Number as PropType<BaseUiRatingProps<Sizes>['maxRating']>,
                    default: options?.props?.maxRating?.default ?? 5,
                    required: options?.props?.maxRating?.required ?? false,
                },
                editable: {
                    type: Boolean as PropType<BaseUiRatingProps<Sizes>['editable']>,
                    default: options?.props?.editable?.default,
                    required: options?.props?.editable?.required ?? false,
                },
                size: {
                    // @ts-ignore
                    type: String as PropType<BaseUiRatingProps<Sizes>['size']>,
                    default: options?.props?.size?.default,
                    required: options?.props?.size?.required ?? false,
                },

            },
            slots: Object as SlotsType<BaseUiRatingSlots<Sizes>>,
            emits: {
                'update:modelValue': (value: number) => true,
                'update:form': (value: InternalFormElementValue<number | null>) => true,
            },
        }
    )
}

export default defineComponentBaseUiRating()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiRating.scss" as *;

</style>
